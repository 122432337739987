import { makeStyles } from '@material-ui/core'
import { graphql } from 'gatsby'
import * as React from 'react'
import { ReactElement } from 'react'
import Layout from '../components/Layout'
import { NewsComponent } from '../components/NewsComponent'
import Slider from '../components/Slider'

const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
    fontSize: '1.5em',
    marginLeft: '10px'
  }
})

export const query = graphql`
  query PLNewsQuery {
    allContentXlsxPlNews {
      nodes {
        date
        content
        title
        pictures
      }
    }
    allContentXlsxSlider {
      nodes {
        picture
      }
    }
  }
`

interface News {
  title: string
  date: string
  content: string
  pictures: string
}

interface Picture {
  picture: string
}
interface ISlider {
  nodes: Array<Picture>
}
interface Nodes {
  nodes: Array<News>
}

interface DataSheet {
  allContentXlsxPlNews: Nodes
  allContentXlsxSlider: ISlider
}

export interface Props {
  data: DataSheet
}
const Aktualnosci = ({
  data: {
    allContentXlsxPlNews: { nodes: newsList },
    allContentXlsxSlider: { nodes: pictures }
  }
}: Props): ReactElement => {
  const styles = useStyles()
  return (
    <Layout actualTab="Aktualności">
      <Slider pictures={pictures} />
      <p className={styles.title}>Aktualności</p>
      <NewsComponent newsList={newsList} />
    </Layout>
  )
}

export default Aktualnosci
