import { graphql } from 'gatsby'
import * as React from 'react'
import Aktualnosci, { Props } from './aktualnosci'

export const query = graphql`
  query PLIndexQuery {
    allContentXlsxPlNews {
      nodes {
        date
        content
        title
        pictures
      }
    }
    allContentXlsxSlider {
      nodes {
        picture
      }
    }
  }
`

const Home = (props: Props) => <Aktualnosci {...props} />
export default Home
